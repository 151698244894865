<template>
<div>
  <h1>serverTime</h1>
  <p>
    clientTime: {{ clientTime }}<br/>
    serverTime: {{ serverTime }}<br/>
    delta: {{ delta }}
  </p>
  <h1>String interpolation</h1>
  <p>
    string: {{ s }}<br/>
    interpolated: {{ inject(s, $app) }}<br/>
  </p>
  <h1>Test Tuple Syntax</h1>
  <p>
    <b-button @click="testTupleSyntax()">Test Syntax</b-button>
  </p>
</div>
</template>

<script>
import { getTimeMS } from "@/services/functions"
import { epochms, inject } from "@/services/utils"
import { getLog } from "@/services/log"
const log = getLog("test-utils")

export default {
  data() {
    return {
      clientTime: 0,
      serverTime: 0,
      delta: 0,
      s: "the product name is ${productName}",
    }
  },
  async mounted() {
    this.serverTime = (await getTimeMS()).data;
    this.clientTime = epochms();
    this.delta = this.clientTime - this.serverTime;
  },
  methods: {
    inject,
    testTupleSyntax() {
      log.log("testTupleSyntax");

      function fun() {
        return ["left", 1];
      }
      let [value] = fun();
      log.log("value", value);
    }
  }
}
</script>

<style>

</style>